iframe[width="0"] {
  border: none;
  margin: 0;
  padding: 0;
}

.CalendarDay__selected_span {
  background-color: rgba(165, 187, 196, 0.4);
  border-color: rgba(165, 187, 196, 1);
  color: #353535;
}

.CalendarDay__selected {
  background-color: rgba(165, 187, 196, 0.8);
  border-color: rgba(165, 187, 196, 1);
  color: #353535;
}

.CalendarDay__selected:hover {
  background-color: rgba(230, 0, 70, 1);
  border-color: rgba(230, 0, 70, 1);
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background-color: rgba(230, 0, 70, 0.8);
  border-color: rgba(230, 0, 70, 1);
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
}

.DateInput_input__focused {
  border-color: #e60046;
}

.DateInput_input {
  font-family: exo;
}
